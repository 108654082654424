<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Web Bot Template Settings Dashboard</h5>
                <div>
                    <Button label="Dashboard" class="p-button-sm p-mr-2" @click="goToDashboard" icon="pi pi-th-large" />
                    <Button label="Previous" class="p-button-sm p-mr-1" @click="goToPrevious"
                        icon="pi pi-chevron-left" />
                    <Button label="Next" icon="pi pi-chevron-right" @click="goToNext" class="p-button-sm"
                        iconPos="right" />
                </div>
            </div>
            <div class="p-grid dashboard" style="position: relative" v-if="!showloaderpage">
                <div class="p-col-12 p-md-12">
                    <div class="card widget-social">
                        <div class="p-d-flex">
                            <span class="subtext p-mt-2 p-text-capitalize">
                                <h6>Client Name : {{ this.localClientName }}</h6>
                                <!-- {{ clientsList.maa2 ? clientsList.maa2 : '-' }} {{ clientsList.maa4 ?
                                    `(${clientsList.maa4})` : '' }} -</h6> -->
                            </span>
                        </div>
                    </div>
                </div>

                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="ChatBotSettings" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-envelope" style="font-size: 2rem"></i>
                            <Divider />
                        </template>
                        <template #content>
                            Web Bots
                        </template>
                    </Card>
                </div>

                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="TemplateDescriptionSettings" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-book" style="font-size: 2rem"></i>
                            <Divider />
                        </template>
                        <template #content>
                            Web Bot Template Groups
                        </template>
                    </Card>
                </div>
                <div class="p-col-12 p-md-6 p-lg-3 p-text-center">
                    <Card class="dashboard-card" @click="WebBOTTemplateSetting" style="margin-bottom: 1em">
                        <template #title>
                            <i class="pi pi-book" style="font-size: 2rem"></i>
                            <Divider />
                        </template>
                        <template #content>
                            Web Bot Templates
                        </template>
                    </Card>
                </div>


            </div>
            <div v-if="showloaderpage" style="height: 544px; position: relative">
                <div class="custom-modal-spinner-loader">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from '../../service/ApiService';
import router from '@/router';
import { useRoute } from 'vue-router';

export default {
    data() {
        return {
            clientsList: [],
            clientname: '',
            showloaderpage: false,
            routeParam: '',
            localClientName: '',
            clientnamevalue: '',
        };
    },

    mounted() {
        const route = useRoute();
        this.routeParam = route.params.clientSubId;
        if (localStorage.localClientName) {
            this.localClientName = localStorage.localClientName;
        }

        localStorage.removeItem('webBotId');
        localStorage.removeItem('webBotData');
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    methods: {
        TemplateDescriptionSettings() {
            router.push({
                name: "listTemplateDescription",
                params: { clientSubId: this.routeParam, clientnamevalue: this.localClientName },

            });
        },
        ChatBotSettings() {
            router.push({
                name: "chatbotsettings",
                params: { clientSubId: this.routeParam, clientnamevalue: this.localClientName },

            });
        },
        WebBOTTemplateSetting() {
            router.push({
                name: "web-bot-template-settings",
                params: { clientSubId: this.routeParam, clientnamevalue: this.localClientName },
            });
        },
        goToDashboard() {
            router.push({
                name: "clientdashboard",
                params: { clientId: this.routeParam, clientnamevalue: this.localClientName },

            });
        },
        goToPrevious() {
            router.push({
                name: "whatsapp-template-settings",
                params: { clientSubId: this.routeParam, clientnamevalue: this.localClientName },

            });
        },
        goToNext() {
            router.push({
                name: "whatsapp-numbers",
                params: { clientSubId: this.routeParam, clientnamevalue: this.localClientName },

            });
        }
    },
};
</script>


<style lang="scss" scoped>
/* Responsive */
.p-datatable-customers .p-datatable-tbody>tr>td .p-column-title {
    display: none;
}

.p-button-xs {
    width: 1rem;
}

.p-button.p-button-xs {
    padding: 0.925rem 0.925rem;
}

.p-button.p-button-xs .p-button-icon {
    font-size: 0.675rem;
}

.p-button.p-button-xs.p-button-icon-only.p-button-rounded {
    height: 1rem;
}

::v-deep(.p-progressbar) {
    height: 1rem;
    background-color: #d8dadc;

    .p-progressbar-value {
        background-color: #689f38;
    }

    .p-progressbar-label {
        display: block;
        color: #ffffff;
        line-height: 13px;
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-datatable) {
        &.p-datatable-customers {

            .p-datatable-thead>tr>th,
            .p-datatable-tfoot>tr>td {
                display: none !important;
            }

            .p-datatable-tbody>tr {
                border-bottom: 1px solid #dee2e6;

                >td {
                    text-align: left;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 0 none !important;
                    width: 100% !important;
                    float: left;
                    clear: left;
                    border: 0 none;

                    .p-column-title {
                        padding: 0.4rem;
                        min-width: 30%;
                        display: inline-block;
                        margin: -0.4rem 1rem -0.4rem -0.4rem;
                        font-weight: bold;
                    }

                    .p-progressbar {
                        margin-top: 0.5rem;
                    }
                }
            }
        }
    }
}

.dashboard-card:hover {
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 10px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    cursor: pointer;
    color: #3F51B5;
}
</style>